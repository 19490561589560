
import $ from 'jquery';
import 'timeago';
import './jquery.timeago.fr';
import './jquery.timeago.fr-short';

$(document).ready(function () {
    var $notifDropdown = $('.nav.navbar-nav .dropdown .hasnotifications').parent();
    if ($notifDropdown.length) {
      $.ajax({
        dataType: 'json',
        url: Routing.generate('notifications_list'),
        success: function(data) {
          if (data.unread_count > 0) {
            $notifDropdown.find('.hasnotifications .badge').html(data.unread_count).show();
            $notifDropdown.find('.count').html(Translator.trans('notification.unread_count', {'count': data.unread_count}));
            $notifDropdown.find('.hasnotifications .badge').trigger('notification.show')

            let storageLastNotificationDate = localStorage.getItem('lastUnreadNotification');
            if (data.lastNotificationDate > storageLastNotificationDate || storageLastNotificationDate === undefined) {
              playNotificationSound();
              localStorage.setItem('lastUnreadNotification', data.lastNotificationDate);
            }
          }

          $.each(data.data, function (idx, item) {
            var redirectUrl = (item.route ? Routing.generate(item.route, item.routeParams) : '#');
            $notifDropdown.find('.scrollthis').append(
              '<li>'
              +'<a href="'+ redirectUrl +'" data-id="'+ item.id +'" class="notification notification-'+ item.type +' '+ (!item.read ? 'active' : '') +'">'
              +'<span class="time">'+ $.timeago(item.createdAt) +'</span><i class="fa fa-'+ item.icon +'"></i>'
              +'<span class="msg">'+ Translator.trans(item.subject) +'</span>'
              +'</a>'
              +'</li>'
            );
          });
        }
      });

      $notifDropdown.on('click', 'a.notification', function(evt) {
        var $that = $(this);
        evt.preventDefault();
        $.ajax({
          url: Routing.generate('notifications_mark_as_read', {'id': $that.data('id')}),
          success: function(data) {
            if (1 == data) {
              $that.removeClass('active');
              var $badge = $that.closest('.dropdown').find('.badge');
              var count = parseInt($badge.html(), 10) -1;
              $notifDropdown.find('.count').html(Translator.trans('notification.unread_count', {'count': count}));
              if (count == 0) {
                $badge.hide();
              } else {
                $badge.html(count).show('bounce', {'distance': 20}, 500);
              }
            }
            if ('#' != $that.prop('href') && undefined != $that.prop('href')) {
              document.location.href = $that.prop('href');
            }
          }
        });
      });

      $notifDropdown.on('click', '.markAllRead', function(evt) {
        var $that = $(this);
        evt.preventDefault();
        $.ajax({
          url: Routing.generate('notifications_mark_as_read', {'id': 'all'}),
          success: function(data) {
            if (data > 0) {
              $notifDropdown.find('.notification').removeClass('active');
              var $badge = $that.closest('.dropdown').find('.badge');
              var count = 0;
              $notifDropdown.find('.count').html(Translator.trans('notification.unread_count', {'count': count}));
              $badge.hide();
            }
          }
        });
      });
    }

    function playNotificationSound(soundObj) {
      let sound = new Audio('/bundles/notifications/sound/notification.mp3');
      sound.play();
    }
});
