
// ##############################
// ########## B2C / B2B #########
// ##############################

let $contactType        = $('#quote_request_contactType');
let $contactName        = $('#fg-for-quote_request_contactName');
let $contactCompanyName = $('#fg-for-quote_request_contactCompanyName');
let $contactStatus      = $('#fg-for-quote_request_contactStatus');
let $contactCompanyTypology = $('#fg-for-quote_request_contactCompanyTypology');
let $siret              = $('#fg-for-quote_request_siret');
let $agency             = $('#quote_request_agency');
let $offerManager       = $('#quote_request_offerManager');

$(document).ready(function() {
  if ($agency.length > 0) {
    displayOfferManager();
  }

  $agency.on('change', function () {
    displayOfferManager();
  })
});

function displayOfferManager()
{
  $offerManager.find('option').not(':first').hide();
  $agency.val()
  $offerManager.find('option').each(function (key, value) {
    if (undefined !== $(this).data('agencies')) {
      let agencies = $(this).data('agencies').toString();
      if (agencies.split(',').includes($agency.val())) {
        $(this).show();
      }
    }
  });
}

$contactType.on('change', function (event) {
  toggleB2CB2B()
});

$(document).ready(function() {
  toggleB2CB2B()
});

function toggleB2CB2B()
{
  if ('b2b' === $contactType.val()) {
    $contactName.hide();
    $contactCompanyName.show();
    $contactStatus.show();
    $siret.show();
    $contactCompanyTypology.show();
  } else if ('b2c' === $contactType.val()) {
    $contactStatus.hide();
    $contactCompanyName.hide();
    $contactName.show();
    $siret.hide();
    $contactCompanyTypology.hide();
  }
}

let $inputStatus = $('#quote_request_status');
let $blockStatusAccepted = $('#block-status-accepted');
let $blockStatusRefused = $('#block-status-refused');

$(document).ready(function() {
  toggleStatus();
});

$inputStatus.on('change', function() {
  toggleStatus();
});

function toggleStatus() {
  let inputStatus = $inputStatus.val();

  $blockStatusAccepted.hide();
  $blockStatusRefused.hide();

  if ('accepted' === inputStatus) {
    $blockStatusAccepted.show();
  }

  if ('refused' === inputStatus) {
    $blockStatusRefused.show();
  }
}

// ##############################
// ########### EMAILS ###########
// ##############################

let $email        = $('#quote_request_contactEmail');
let $emailSendTo  = $('#quote_request_mailSendTo');

$email.on('focusout', function (event) {
  checkEmail();
  copyEmail();
});

$email.on('keyup', function (event) {
  checkEmail();
  copyEmail();
});

$(document).ready(function() {
  checkEmail();
});

function copyEmail()
{
  let emailVal = $email.val();
  let emailSendToVal = $emailSendTo.val();
  $emailSendTo.val(emailVal);
}

function checkEmail()
{
  let emailVal = $email.val();

  if (emailVal !== "" && !isValidEmail(emailVal)) {
    let message = '<ul class="help-block help-warning list-unstyled"><li><i class="fa fa-warning"></i>L\'adresse e-mail n\'est pas valide.</li></ul>';
    $('.help-warning').remove();
    $email.after(message);
  } else {
    $('.help-warning').remove();
  }
}

function isValidEmail (email)
{
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

$('div[data-action="preview"]').on('click', function (event) {

  let content = CKEDITOR.instances.quote_request_mailContent.getData();

  let data = {
    content: content,
  };

  $.ajax({
    url: $(this).data('url'),
    type: 'POST',
    data: data,
    dataType: 'html',
    success: function(data, status) {
      $('#emailPreviewModal .modal-body').html(data);
      $('#emailPreviewModal').modal('show');
    },
  });
});

// ################################
// ########### DROPZONE ###########
// ################################
let Dropzone = require('dropzone');

let $documentTable = $('#documents-table');
let $dropzoneSnippet = $('#form_snippet_image');

if (0  < $dropzoneSnippet.length) {
  let _actionToDropZone = $dropzoneSnippet.attr('action');

  Dropzone.autoDiscover = false;
  let myDropzone = new Dropzone("#form_snippet_image", {

    url: _actionToDropZone,
    init: function() {
      this.on("success", function(file, response) {
        file.documentId = response.id;
        $documentTable.append('<tr><td>'+response.name+'</td><td><button disabled class="btn btn-danger" data-documentId="'+response.id+'"><i class="fa fa-trash"></i></button></td></tr>');
      })
    },
    addRemoveLinks: true,
    removedfile: function(file) {
      var documentId = file.documentId;

      $.ajax({
        type: 'DELETE',
        url: _actionToDropZone,
        data: {documentId: documentId},
        success: function(data){
          console.log(documentId);
          let tr = $('button[data-documentid="'+documentId+'"').closest('tr');
          console.log(tr);
          tr.remove();
        }
      });

      var _ref;
      return (_ref = file.previewElement) != null ? _ref.parentNode.removeChild(file.previewElement) : void 0;
    }
  });

  $documentTable.on('click', 'tr button[data-action="remove"]', function (event) {
    let tr = $(this).closest('tr');
    let documentId = $(this).data('documentid');

    $.ajax({
      type: 'DELETE',
      url: _actionToDropZone,
      data: {documentId: documentId},
      success: function(data){
        tr.remove();
      }
    });
  });
}


// ---------------------------------------------
// Disable submit button when form is submitted
// ---------------------------------------------

$(document).ready(function() {
  var editForm = document.getElementById('editForm');

  if (editForm) {
    if (editForm.addEventListener) {
      //Modern browsers
      editForm.addEventListener('submit', callback, false);
    } else if(editForm.attachEvent) {
      //Old IE
      editForm.attachEvent('onsubmit', callback);
    }

    function callback(event) {
      // event.preventDefault();
      var submitBtn = document.querySelectorAll('button[type=submit]');

      for (var i = 0; i < submitBtn.length; i++) {
        submitBtn[i].removeAttribute('disabled');
        submitBtn[i].disabled = false;
      }

      // editForm.submit();
    }
  }
});

// ##############################
// ########## AMOUNT HT #########
// ##############################

$(document).ready(function() {

  $('#refresh-ttc').on('click', function() {
    $(this).find('i').addClass('fa-spin');

    let $amountPrice = $('#quote_request_amountPrice');
    let totalAmountHT = calculate();
    $amountPrice.val(totalAmountHT);

    setTimeout(function () {
      $('#refresh-ttc').find('i').removeClass('fa-spin');
    }, 500);

  });

  function calculate() {
    let amountTr = $('#quote_request_amountPriceHTs tr[id^=quote_request_amountPriceHTs_]');
    let totalAmountHT = 0;

    $.each(amountTr, function(i, el) {
      let amount  = $(el).find('input[id^=quote_request_amountPriceHTs_][id$=_amount]').val();
      let vat     = $(el).find('select[id^=quote_request_amountPriceHTs_][id$=_vat]').val();

      totalAmountHT += parseFloat(amount.replace(',','.').replace(' ','')) * (1 + (parseInt(vat) / 100 / 100));
    });

    return Number(totalAmountHT.toFixed(2));
  }
});
