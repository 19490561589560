require('../css/common.scss');
require('../css/app.scss');
require('../css/quote_request.scss');
require('../css/notification.scss');

require('./quote_request.js');
import '../../src/LePhare/NotificationsBundle/Resources/public/js/notifications.js';

import 'air-datepicker';
import 'air-datepicker/dist/js/i18n/datepicker.fr';
// let timeago = require('../../src/LePhare/NotificationsBundle/Resources/public/js/jquery.timeago');
